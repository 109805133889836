import axios from "axios";

axios.defaults.headers.common["X-CSRF-TOKEN"] = document.cookie.split("=")[1];

//const baseUrl = 'http://localhost:4810/Api';

export default {
    // ********************************************************************| Authintecations |***********************************************************

    login(bodyObjeect) {
        return axios.post(`/Security/loginUser`, bodyObjeect);
    },

    AddMuntisbl(bodyObjeect) {
        return axios.post(`/api/admin/Municipalities/Add`, bodyObjeect);
    },

    AddCity(bodyObjeect) {
        return axios.post(`/api/admin/Cities/Add`, bodyObjeect);
    },
    AddMadisons(bodyObjeect) {
        return axios.post(`/api/admin/Madisons/Add`, bodyObjeect);
    },
    IsLoggedin() {
        return axios.get(`/Security/IsLoggedin`);
    },

    Logout() {
        return axios.post(`/Security/Logout`);
    },

    // ********************************************************************| Helper |***********************************************************
    GetCities() {
        return axios.get(`api/admin/Cities/GetAll`);
    },

    GetMunicipalitiesName(id) {
        return axios.get(`/Api/Admin/Helper/GetMunicipalitiesName?id=${id}`);
    },

    GetMunicipalitiesByCiteisID(id) {
        return axios.get(`api/admin/Municipalities/GetById?CityId=${id}`);
    },

    GetByIdKidneyCenterId(pageNo, pageSize,id) {
        return axios.get(`api/admin/Patients/GetById?pageNo=${pageNo}&pageSize=${pageSize}&Id=${id}&CenterId=${id}`);
    },

    GetPatinetByCityId(pageNo, pageSize,id) {
        return axios.get(`api/admin/Patients/GetByCityId?pageNo=${pageNo}&pageSize=${pageSize}&Id=${id}`);
    },

    GetByPatientInfo(Number, NationalId) {
        return axios.get(`api/admin/Patients/GetByPatientInfo?FileNumber=${Number}&NationalId=${NationalId}`);
    },

    UpDateMuntisbl(bodyObject) {
        return axios.post(`api/admin/Municipalities/Edit`, bodyObject);
    },

    UpDateCity(bodyObject) {
        return axios.post(`api/admin/Cities/Edit`, bodyObject);
    },

    UpDateMadison(bodyObject) {
        return axios.post(`api/admin/Madisons/Edit`, bodyObject);
    },


    KidneyCenters(pageNo, pageSize) {
        return axios.get(
            `api/admin/Centers/Get?pageNo=${pageNo}&pageSize=${pageSize}`
        );
    },

    deleteMunticbil(id) {
        return axios.post(`api/admin/Municipalities/${id}/Delete`);
    },

    deleteCity(id) {
        return axios.post(`api/admin/Cities/${id}/Delete`);
    },

    deleteMadisons(id) {
        return axios.post(`api/admin/Madisons/${id}/Delete`);
    },

    deleteKidneyCenters(id) {
        return axios.post(`api/admin/Centers/${id}/Delete`);
    },

    AddKidneyCenters(bodyObject) {
        return axios.post(`/api/admin/Centers/Add`, bodyObject);
    },

    UpdateKidneyCenters(bodyObject) {
        return axios.post(`api/admin/Centers/Edit`, bodyObject);
    },

    GetAllMunicipalities(cityid) {
        return axios.get(`api/admin/Municipalities/GetAll?cityid=${cityid}`);
    },

    GetLocationsName(id) {
        return axios.get(`/Api/Admin/Helper/GetLocationsName?id=${id}`);
    },

    ChangePasswords(object) {
        return axios.post(`/Api/Admin/User/ChangePassword`, object);
    },

    GetUsers(pageNo, pageSize, UserType, HospitalsId) {
        return axios.get(
            `/Api/Admin/User/Get?pageNo=${pageNo}&pagesize=${pageSize}&userType=${UserType}&CenterId=${HospitalsId}`
        );
    },

    GetUsersHospital(pageNo, pageSize, KidneyCentersId) {
        return axios.get(
            `/Api/Admin/User/GetUsersHospital?pageNo=${pageNo}&pagesize=${pageSize}&CenterId=${KidneyCentersId}`
        );
    },

    GetUsersPerType(pageNo, pageSize, UserType) {
        return axios.get(
            `/Api/Admin/User/GetUsersPerType?pageNo=${pageNo}&pagesize=${pageSize}&userType=${UserType}`
        );
    },

    AddUser(bodyObject) {
        return axios.post("/Api/Admin/User/Add", bodyObject);
    },
    EditUser(bodyObject) {
        return axios.post("/Api/Admin/User/Edit", bodyObject);
    },

    ChangeStatusUser(Id) {
        return axios.post(`/Api/Admin/User/${Id}/ChangeStatus`);
    },

    DeleteUser(Id) {
        return axios.post(`/Api/Admin/User/${Id}/Delete`);
    },

    UploadImage(bodyObject) {
        return axios.post("/Api/Admin/User/UploadImage", bodyObject);
    },

    EditUsersProfile(bodyObject) {
        return axios.post("/Api/Admin/User/EditUsersProfile", bodyObject);
    },

    ChangePassword(userPassword) {
        return axios.post(`/Api/Admin/User/ChangePassword`, userPassword);
    },

    // **********************************    Applications ***********************

    AddApplications(bodyObject) {
        return axios.post(`/Api/Admin/Applications/Add`, bodyObject);
    },

    EditApplications(bodyObject) {
        return axios.post(`/Api/Admin/Applications/Edit`, bodyObject);
    },

    GetApplications(pageNo, pageSize, MunicipalitId, SelectedHospitalsId) {
        return axios.get(
            `/Api/Admin/Applications/Get?pageno=${pageNo}&pagesize=${pageSize}&MunicipalitId=${MunicipalitId}&selectedHospitalsId=${SelectedHospitalsId}`
        );
    },

    GetAttachments(id) {
        return axios.get(`api/admin/Patients/GetAttachments?PatientId=${id}`);
    },

    AddAttachment(bodyObject) {
        return axios.post(`api/admin/Patients/AddAttachments`, bodyObject);
    },

    DeleteAttachment(id) {
        return axios.post(`api/admin/Patients/${id}/RemoveAttachments`);
    },

    AddImage(bodyObject) {
        return axios.post(`api/admin/Patients/ChangePatintImage`, bodyObject);
    },

    GetPhones(id) {
        return axios.get(
            `api/admin/Patients/GetPatientPhoneNumbers?PatientId=${id}`
        );
    },

    AddPhones(bodyObject) {
        return axios.post(`api/admin/Patients/AddPatientPhoneNumbers`, bodyObject);
    },

    DeletePhones(id) {
        return axios.post(`api/admin/Patients/${id}/DeletePatientPhoneNumbers`);
    },

    deleteApp(id) {
        return axios.post(`/Api/Admin/Applications/${id}/delete`);
    },

    GetFilterById(id) {
        return axios.get(`api/admin/Filters/GetById?DeviceId=${id}`);
    },

    GetBranchesName(id) {
        return axios.get(`/Api/Admin/Applications/GetBranchesNames?cityId=${id}`);
    },
    GetPatientSchedule(id) {
        return axios.get(`api/admin/Patients/GetPatientSchedule?PatientId=${id}`);
    },
    GetBranchesAllName() {
        return axios.get(`/Api/Admin/Applications/GetAllBranchesNames`);
    },

    // **********************************    Hospitals ***********************
    AddPatientSchedule(b) {
        return axios.post(`api/admin/Patients/AddPatientSchedule`, b);
    },

    updatePatientSchedule(b) {
        return axios.post(`api/admin/Patients/EditPatientSchedule`, b);
    },

    AcceptPatientSchedule(id) {
        return axios.post(`api/admin/Patients/${id}/AcceptPatientSchedule`);
    },


    rejectPatientSchedule(id) {
        return axios.post(`api/admin/Patients/${id}/DeletePatientSchedule`);

    },
    GetHospitalsAllName(MunicipalitId) {
        return axios.get(
            `/Api/Admin/Hospitals/GetAllHospitalsNames?MunicipalitId=${MunicipalitId}`
        );
    },

    DeletePatientSchedule(id) {
        return axios.post(`api/admin/Patients/${id}/DeletePatientSchedule`);
    },

    AddHospitals(bodyObject) {
        return axios.post(`/Api/Admin/Hospitals/Add`, bodyObject);
    },

    GetHospitals(pageNo, pageSize, MunicipalitId) {
        return axios.get(
            `/Api/Admin/Hospitals/Get?pageno=${pageNo}&pagesize=${pageSize}&MunicipalitId=${MunicipalitId}`
        );
    },

    EditHospitals(bodyObject) {
        return axios.post(`/Api/Admin/Hospitals/Edit`, bodyObject);
    },

    deleteHospitals(id) {
        return axios.post(`/Api/Admin/Hospitals/${id}/delete`);
    },

    GetDashboardInfo() {
        return axios.get(`/Api/Admin/Reports/GetDashboardInfo`);
    },

    GetRequiment() {
        return axios.get(`/Api/Admin/Municipalities/GetRequiment`);
    },

    getManticiplintKednyCenter(id) {
        return axios.get(
            `api/admin/Patients/GetByMunicipalitId?MunicipalitId=${id}`
        );
    },
    /////   Devices ///////

    GetDevices(pageNo, pageSize) {
        return axios.get(
            `api/admin/Devices/Get?pageNo=${pageNo}&pageSize=${pageSize}`
        );
    },

    GetAllDevices() {
        return axios.get(`api/admin/Devices/GetAll`);
    },

    AddDevices(bodyObject) {
        return axios.post(`api/admin/Devices/Add`, bodyObject);
    },

    UpdateDevices(bodyObject) {
        return axios.post(`api/admin/Devices/Edit`, bodyObject);
    },

    deleteDevices(id) {
        return axios.post(`api/admin/Devices/${id}/Delete`);
    },

    GetAllCompanies() {
        return axios.get(`api/admin/Companies/GetAll`);
    },

    GetAllDiseases() {
        return axios.get(`api/admin/Disease/GetAll`);
    },
    GetDviceById(id) {
        return axios.get(
            `api/admin/Devices/GetById?pageNo=1&pageSize=1000&CompanyId=${id}`
        );
    },
    /////   Devices ///////

    AddDeviceToKindycenter(bodyObject) {
        return axios.post(`api/admin/KidneyCentersDevices/Add`, bodyObject);
    },
    /////// Company   //////////////

    deleteDeviceKidny(id) {
        return axios.post(`api/admin/KidneyCentersDevices/${id}/Delete`);
    },
    GetCompany(pageNo, pageSize) {
        return axios.get(
            `api/admin/Companies/Get?pageNo=${pageNo}&pageSize=${pageSize}`
        );
    },
    GetDisease(pageNo, pageSize) {
        return axios.get(
            `api/admin/Disease/Get?pageNo=${pageNo}&pageSize=${pageSize}`
        );
    },

    AddCompany(bodyObject) {
        return axios.post(`api/admin/Companies/Add`, bodyObject);
    },
    AddDisease(bodyObject) {
        return axios.post(`api/admin/Disease/Add`, bodyObject);
    },
    GetFelter(pageNo, pageSize) {
        return axios.get(
            `api/admin/Filters/Get?pageNo=${pageNo}&pageSize=${pageSize}`
        );
    },
    UpdateCompany(bodyObject) {
        return axios.post(`api/admin/Companies/Edit`, bodyObject);
    },
    UpdateDisease(bodyObject) {
        return axios.post(`api/admin/Disease/Edit`, bodyObject);
    },
    deleteCompany(id) {
        return axios.post(`api/admin/Companies/${id}/Delete`);
    },
    deleteDisease(id) {
        return axios.post(`api/admin/Disease/${id}/Delete`);
    },
    deleteFelter(id) {
        return axios.post(`api/admin/Filters/${id}/Delete`);
    },

    DeletePatientAttendance(id) {
        return axios.post(`api/admin/Patients/${id}/DeletePatientAttendance`);
    },

    UpdateFilters(bodyObject) {
        return axios.post(`api/admin/Filters/Edit`, bodyObject);
    },

    AddFilters(bodyObject) {
        return axios.post(`api/admin/Filters/Add`, bodyObject);
    },

    GetPatient(pageNo, pageSize, CityId2, MunicipalitiesId, centerId) {
        return axios.get(
            `api/admin/Patients/Get?pageNo=${pageNo}&pageSize=${pageSize}&CityId2=${CityId2}&MunicipalitiesId=${MunicipalitiesId}&CenterId=${centerId}`
        );
    },

    deletePatient(id) {
        return axios.post(`api/admin/Patients/${id}/Delete`);
    },

    ShowDevices(id) {
        return axios.get(
            `api/admin/KidneyCentersDevices/Get?CentersId=${id}`
        );
    },

    GetNationalites() {
        return axios.get(`api/admin/Nationalites/GetAll`);
    },

    /////// Company //////////

    // **********************************    Municipalities ***********************

    //   GetAllMunicipalities() {
    //     return axios.get(`/Api/Admin/Municipalities/GetAll`);
    //   },

    //   AddMunicipalities(bodyObject) {
    //     return axios.post(`/Api/Admin/Municipalities/Add`, bodyObject);
    //   },

    GetMunicipalities(pageNo, pageSize) {
        return axios.get(
            `/Api/Admin/Municipalities/Get?pageno=${pageNo}&pagesize=${pageSize}`
        );
    },

    GetCity(pageNo, pageSize) {
        return axios.get(
            `/Api/Admin/Cities/Get?pageno=${pageNo}&pagesize=${pageSize}`
        );
    },

    GetProtocols(pageNo, pageSize) {
        return axios.get(
            `api/admin/Protocols/Get?pageNo=${pageNo}&pageSize=${pageSize}`
        );
    },

    GetAllDesiess() {
        return axios.get(`api/admin/Disease/GetAll`);
    },

    getMadisons() {
        return axios.get(`api/admin/Madisons/GetAll`);
    },

    AddProtocols(schema) {
        return axios.post(`api/admin/Protocols/Add`, schema);
    },

    editProtocol(schema) {
        return axios.post(`api/admin/Protocols/Edit`, schema);
    },

    getProtocolsList(id) {
        return axios.get(`api/admin/Protocols/GetProtocolMadisone?ProtocolId=${id}`);
    },
    DeleteProtocols(id) {
        return axios.post(` api/admin/Protocols/${id}/Delete`);
    },

    AddProtocolUpdateMadison(schema) {
        return axios.post(`api/admin/Protocols/AddProtocolMadisone`, schema);
    },

    deleteElemnt(id) {
        return axios.post(`api/admin/Protocols/${id}/DeleteProtocolMadisone`);
    },
    GetMadisons(pageNo, pageSize) {
        return axios.get(
            `/Api/Admin/Madisons/Get?pageno=${pageNo}&pagesize=${pageSize}`
        );
    },
    //   EditMunicipalities(bodyObject) {
    //     return axios.post(`/Api/Admin/Municipalities/Edit`, bodyObject);
    //   },

    //   DeleteMunicipalities(id) {
    //     return axios.post(`/Api/Admin/Municipalities/${id}/Delete`);
    //   },

    // **********************************    GetDevicesName ***********************

    GetDevicesNames() {
        return axios.get(`/Api/Admin/Devices/GetDevicesName`);
    },

    GetDevicesbyCompany(id) {
        return axios.get(`/Api/Admin/Devices/GetById?CompanyId=${id}`);
    },

    GetFilterNames(id) {
        return axios.get(`/Api/Admin/Filters/GetById?DeviceId=${id}`);
    },

    GetAllFilterNames() {
        return axios.get(`/Api/Admin/Devices/GetAllNames`);
    },

    // **********************************    Get Change Center ***********************

    GetCahngeCenter(pageNo, pageSize) {
        return axios.get(
            `/Api/Admin/Patients/GetCahngeCenter?pageno=${pageNo}&pagesize=${pageSize}`
        );
    },

    GetChangeAttachments(id) {
        return axios.get(
            `/Api/Admin/Patients/GetChangeAttachments?PatientsChangeCenterId=${id}`
        );
    },

    GetKidneyCentersNames() {
        return axios.get(`api/admin/Centers/GetAll`);
    },

    RejectChangeRequest(bodyObject) {
        return axios.post(`api/admin/Patients/RejectChangeRequest`, bodyObject);
    },

    AccepteChangeRequest(id) {
        return axios.post(`api/admin/Patients/${id}/AccepteChangeRequest`);
    },

    RemoveCahngeAttachments(id) {
        return axios.post(`api/admin/Patients/${id}/RemoveCahngeAttachments`);
    },

    deleteItemAttachment(id) {
        return axios.post(`api/admin/Patients/${id}/RemoveCahngeAttachments`);
    },

    AddChangeAttachments(bodyObject) {
        return axios.post(`api/admin/Patients/AddChangeAttachments`, bodyObject);
    },

    AddItemChagenRequest(bodyObject) {
        return axios.post(`api/admin/Patients/AddChangeRequest`, bodyObject);
    },
    GetPatientAttendance(pageNo, pageSize) {
        return axios.get(`api/admin/Patients/GetPatientAttendance?pageNo=${pageNo}&pageSize=${pageSize}`);
    },

    // **********************************    Get Change Center ***********************
    getChronicDiseases() {
        return axios.get(`api/admin/ChronicDisease/GetAll`);
    },
  //****************************************************************************************
  GetUsedDaily() {
    return axios.get(`/Api/Admin/Reports/GetUsedDaily`);
  },

  GetPatientNames() {
    return axios.get(`/Api/Admin/Patients/GetAll`);
  },

  GetCompanyNames() {
    return axios.get(`/Api/Admin/Companies/GetAll`);
  },
  GetAllProtocols() {
    return axios.get(`api/admin/Protocols/Get?pageNo=1&pageSize=100000`);
  },

  GetUsed() {
    return axios.get(`/Api/Admin/Reports/GetUsed`);
  },

  GetByDate(day1, day2) {
    return axios.get(
      `/Api/Admin/Reports/GetUsedByDate?From=${day1}&To=${day2}`
    );
    },

    GetUsedRequierd() {
    return axios.get(
      `/Api/Admin/Reports/GetUsedRequierd`
    );
  },

  AddPatientAttendance(bodyObject) {
    return axios.post(`api/admin/Patients/AddPatientAttendance`, bodyObject);
  },

  GetByFileNumberTransfare(id){
    return axios.get(`api/admin/Patients/GetCahngeCenterByFileNumber?FileNumber=${id}`);

  },

  DayUsesDay(){
    return axios.get(`api/admin/Reports/GetForLab`);

  },
  GetKednyCenterByCitie(id) {
    return axios.get(`api/admin/Centers/GetById?MunicipalityId=${id}`);
  },

  AddPationt(schema) {
    return axios.post(`api/admin/Patients/Add`, schema);
  },

  UpdatePationt(schema) {
    return axios.post(`api/admin/Patients/Edit`, schema);
  },

  UpdatePationtScagualList(schema) {
    return axios.post(`api/admin/Patients/EditPatientSchedule`, schema);
  },

  //AddHospitals(bodyObject) {
  //    return axios.post(`/Api/Admin/Devices/Add`, bodyObject);
  //},

  //GetHospitals(pageNo, pageSize, SelectedCityId) {
  //    return axios.get(`/Api/Admin/Devices/GetBranches?pageno=${pageNo}&pagesize=${pageSize}&selectedCityId=${SelectedCityId}`);
  //},

  //EditHospitals(bodyObject) {
  //    return axios.post(`/Api/Admin/Devices/EditBranches`, bodyObject);
  //},

  //deleteHospitals(id) {
  //    return axios.post(`/Api/Admin/Devices/${id}/deleteBranches`);
  //},
};
