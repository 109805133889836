import Swal from "sweetalert2";
import moment from "moment";
import flatPickr from "vue-flatpickr-component";
export default {
    name: "Add",
    created() {
        console.log(this.$parent.SelectedItem);
        this.GetAllCompanies();
        this.GetAllChronicDiseases();
        this.GetCities();
        this.GetNationalites();
        this.GetMadisoneType(this.$parent.SelectedItem.madisoneType);
        this.GetDviceById();
        this.GetFilterById();
        this.GetAllMunicipalities();
        this.GetKednyCenter();
        this.list(this.$parent.SelectedItem.id);
        
    },
    components: {
        flatPickr,
    },
    filters: {
        moment: function (date) {
            if (date === null) {
                return "فارغ";
            }
            // return moment(date).format('MMMM Do YYYY, h:mm:ss a');
            return moment(date).format("MMMM Do YYYY");
        },
    },
    data() {
        const validateIsLessThanOrEqual1920 = (rule, value, callback) => {
            const year = new Date(value).getFullYear();
            if (year <= 1920) {
                callback(new Error('سنة بداية المرض يجب ان يكون اكبر من 1920'));
            } else {
                callback();
            }
        };
        return {
            companies: [],
            chronicDiseases:[],
            treatmentsType: [{ id: 1, name: "النوع الأول" }, { id: 2, name: "النوع الثاني" }, { id: 3, name: "النوع الثالث" }, { id: 4, name: "النوع الرابع" }, { id: 5, name: "النوع الخامس" }],
            madisoneTypes: [{ id: 2, name: "تغير نمط الحياة" }, { id: 3, name: " اقراص منظمة" }, { id: 4, name: "الانسولين  (حقنة)" }, { id: 5, name: "طرق متعددة (حقنة + اقراص+تغير نمط الحياة)" }],
            companiesId: this.$parent.SelectedItem?.filter?.device?.company
                ?.companyId,
            ProtocolId: "",
            Center: "",
            Day: "",
            Felters: [],
            showMenu: false,
            activeClass: "js-active position-relative",
            activeStep: 0,
            formSteps: 3,
            step: 0,
            DeviceId: this.$parent.SelectedItem?.filter?.device?.deviceId,
            FeltersId: "",
            Municipalities: [],
            options: [
                {
                    value: "Option1",
                    label: "Option1",
                },
                {
                    value: "Option2",
                    label: "Option2",
                },
                {
                    value: "Option3",
                    label: "Option3",
                },
                {
                    value: "Option4",
                    label: "Option4",
                },
                {
                    value: "Option5",
                    label: "Option5",
                },
            ],
            config: {
                allowInput: true,
            },
            CityId: "",
            CityId2:
                this.$parent.SelectedItem?.center?.municipality.city?.cityId || "",
            Cities: [],
            citis: [],
            Nationality: "",
            Nationaly: [],
            ProfileruleForm: {
                FirstName: this.$parent.SelectedItem?.firstName || "",
                MiddleName: this.$parent.SelectedItem?.middleName || "",
                GrandfatherName: this.$parent.SelectedItem?.grandfatherName || "",
                LastName: this.$parent.SelectedItem?.lastName || "",
                NationalyId: this.$parent.SelectedItem?.nationality?.nationalyId || "",
                Gender: this.$parent.SelectedItem?.gender || "",
                DateOfBirth:
                    this.$parent.SelectedItem?.dateOfBirth?.split("T")[0] || "",
                Passport: this.$parent.SelectedItem?.passport || "",
                Workplace: this.$parent.SelectedItem?.workplace || "",
                MaritalStatus: this.$parent.SelectedItem?.maritalStatus || "",
                Address: this.$parent.SelectedItem?.address || "",
                CityId: this.$parent.SelectedItem?.city?.cityId || "",
                NID: this.$parent.SelectedItem?.nid || "",
                Lenght: this.$parent.SelectedItem?.lenght || "",
                Weight: this.$parent.SelectedItem?.weight || "",
                Phone: this.$parent.SelectedItem?.phone || "",
                ExtraPhone: this.$parent.SelectedItem?.extraPhone || "",
                FileNumber: this.$parent.SelectedItem?.fileNumber || "",
            },
            Profilerules: {
                //Phone: this.$helper.Required(),
               // ExtraPhone: this.$helper.Required(),
                Weight: this.$helper.Required(),
                Lenght: this.$helper.Required(),
                FirstName: this.$helper.Required(),
                MiddleName: this.$helper.Required(),
                GrandfatherName: this.$helper.Required(),
                LastName: this.$helper.Required(),
                NationalyId: this.$helper.Required(),
                Gender: this.$helper.Required(),
                DateOfBirth: this.$helper.Required(),
                Workplace: this.$helper.Required(),
                MaritalStatus: this.$helper.Required(),
                Address: this.$helper.Required(),
                CityId: this.$helper.Required(),
                FileNumber: this.$helper.Required(),
            },
            KidnyCenter: [],
            MunicipalitiesId: this.$parent.SelectedItem?.center?.municipality
                ?.municipalityId,
            ContactruleForm: {
                BloodType: this.$parent.SelectedItem?.bloodType,
                ViralAssays: this.$parent.SelectedItem?.viralAssays,
                FailureDate: this.$parent.SelectedItem?.failureDate?.split("T")[0] || "",
                FailureCause: this.$parent.SelectedItem?.failureCause,
                ChronicDisease: this.$parent.SelectedItem?.chronicDisease,
            },
            Contactrules: {
               // BloodType: this.$helper.Required(),
                ViralAssays: this.$helper.Required(),
                FailureDate: [
                    this.$helper.Required(),
                    { validator: validateIsLessThanOrEqual1920, trigger: 'change' }
                ],
                FailureCause: this.$helper.Required(),
            },
            CopyruleForm: {
                CenterId: this.$parent.SelectedItem?.center?.centerId,
                ProtocolId: null,
                MadisoneType: null,
                MadisoneId: this.$parent.SelectedItem?.madisone?.madisoneId,
                QuntityBerMounth: this.$parent.SelectedItem?.quntityBerMounth,
            },
            Copyrules: {
                CenterId: this.$helper.Required(),
                ProtocolId: this.$helper.Required(),
            },
            CopyruleForm2: {
                Notes: this.$parent.SelectedItem?.notes,
            },
            Copyrules2: {
                Notes: this.$helper.Required(),
            },

            FinancialruleForm: {
                Value: 0,
            },
            Financialrules: {
                Value: this.$helper.NumberOnlyRequired(),
            },
            CourseruleForm: {
                AcademicLevelId: "",
                AcademicSpecializationId: "",
            },
            Courserules: {
                AcademicLevelId: this.$helper.Required(),
                AcademicSpecializationId: this.$helper.Required(),
            },
            ruleForm: {
                FirstName: "",
                FatherName: "",
                GrandFatherName: "",
                SirName: "",
                LoginName: "",
                BirthDate: "",
                Email: "",
                Phone: "",
                HospitalId: null,
                DeviceId: this.$parent.SelectedItem?.filter?.device?.deviceId,
                FilterTypeId: null,
                OldId: "",
                FamilyName: "",
                Nationality: [],
                Workplace: "",
                SocialStatus: null,
                Nid: "",
                Address: "",
                StartDate: "",
                BloodType: null,
                ViralTestResults: null,
                WashingCount: null,
                Resone: null,
            },
            rules: {
                HospitalId: [
                    { required: true, message: "الرجاء اختيار  الجهة", trigger: "blur" },
                ],
                DeviceId: [
                    {
                        required: true,
                        message: "الرجاء اختيار  نوع الجهاز",
                        trigger: "blur",
                    },
                ],
                FilterTypeId: [
                    {
                        required: true,
                        message: "الرجاء اختيار  نوع الفلتر الخاص بالجهاز",
                        trigger: "blur",
                    },
                ],
                OldId: [
                    {
                        required: true,
                        message: "الرجاء ادخال رقم القيد بالمنظومة",
                        trigger: "blur",
                    },
                    {
                        required: true,
                        pattern: /^[0-9]*$/,
                        message: "الرجاء إدخال ارقام فقط",
                        trigger: "blur",
                    },
                ],
                FirstName: [
                    {
                        required: true,
                        message: "الرجاء إدخال الاسم الاول",
                        trigger: "blur",
                    },
                    {
                        min: 3,
                        max: 25,
                        message: "يجب ان يكون الطول من 3 الي 25",
                        trigger: "blur",
                    },
                    {
                        required: true,
                        pattern: /[\u0600-\u06FF]/,
                        message: "الرجاء إدخال حروف العربية فقط",
                        trigger: "blur",
                    },
                ],
                FatherName: [
                    { required: true, message: "الرجاء إدخال إسم الاب", trigger: "blur" },
                    {
                        min: 3,
                        max: 25,
                        message: "يجب ان يكون الطول من 3 الي 25",
                        trigger: "blur",
                    },
                    {
                        required: true,
                        pattern: /[\u0600-\u06FF]/,
                        message: "الرجاء إدخال حروف العربية فقط",
                        trigger: "blur",
                    },
                ],
                GrandFatherName: [
                    { required: true, message: "الرجاء إدخال إسم الجد", trigger: "blur" },
                    {
                        min: 3,
                        max: 25,
                        message: "يجب ان يكون الطول من 3 الي 25",
                        trigger: "blur",
                    },
                    {
                        required: true,
                        pattern: /[\u0600-\u06FF]/,
                        message: "الرجاء إدخال حروف العربية فقط",
                        trigger: "blur",
                    },
                ],
                FamilyName: [
                    { required: true, message: "الرجاء إدخال اللقب", trigger: "blur" },
                    {
                        min: 3,
                        max: 25,
                        message: "يجب ان يكون الطول من 3 الي 25",
                        trigger: "blur",
                    },
                    {
                        required: true,
                        pattern: /[\u0600-\u06FF]/,
                        message: "الرجاء إدخال حروف العربية فقط",
                        trigger: "blur",
                    },
                ],
                BirthDate: [
                    {
                        required: true,
                        message: "الرجاء اختيار  تاريخ الميلاد",
                        trigger: "blur",
                    },
                ],
                Nationality: [
                    { required: true, message: "الرجاء ادخال  الجنسية", trigger: "blur" },
                ],
                Workplace: [
                    {
                        required: true,
                        message: "الرجاء ادخال  مكان العمل",
                        trigger: "blur",
                    },
                ],
                SocialStatus: [
                    {
                        required: true,
                        message: "الرجاء اختيار الوضع العائلي  ",
                        trigger: "blur",
                    },
                ],
                Nid: [
                    {
                        required: true,
                        message: "الرجاء ادخال  الرقم الوطني",
                        trigger: "blur",
                    },
                    {
                        min: 12,
                        max: 12,
                        message: "يجب ان يكون طول الرقم الوطني 12 رقم ",
                        trigger: "blur",
                    },
                    {
                        required: true,
                        pattern: /^[0-9]*$/,
                        message: "الرجاء إدخال ارقام فقط",
                        trigger: "blur",
                    },
                ],
                Address: [
                    {
                        required: true,
                        message: "الرجاء ادخال  مكان السكن",
                        trigger: "blur",
                    },
                ],
                StartDate: [
                    {
                        required: true,
                        message: "الرجاء ادخال  تاريخ البداية",
                        trigger: "blur",
                    },
                ],
                BloodType: [
                    {
                        required: true,
                        message: "الرجاء ادخال  فصيلة الدم",
                        trigger: "blur",
                    },
                ],
                ViralTestResults: [
                    {
                        required: true,
                        message: "الرجاء اختيار  نتائج التحليل الفيروسية",
                        trigger: "blur",
                    },
                ],
                WashingCount: [
                    {
                        required: true,
                        message: "الرجاء ادخال  عدد فترات الغسيل",
                        trigger: "blur",
                    },
                ],
                Resone: [
                    {
                        required: true,
                        message: "الرجاء اختيار سبب الفشل وتسبب المرض  ",
                        trigger: "blur",
                    },
                ],
            },

            Hospitals: [],
            Devices: [],
            FilterTypes: [],
            company: "",
            loginDetails: null,
        };
    },
    watch: {
        'CopyruleForm.ProtocolId': {
            handler(newValue) {
                switch (newValue) {
                    case 1:
                        this.CopyruleForm.MadisoneType = 1;
                        break;
                    default:
                        {
                            if (this.CopyruleForm.ProtocolId == 1 || this.CopyruleForm.MadisoneType == 1) {

                                this.CopyruleForm.MadisoneType = null;
                            }
                        }
                        break;
                }
            },
            deep:true
        },
        'ProfileruleForm.CityId': {
            handler() {
                this.MunicipalitiesId = null;
                this.CopyruleForm.CenterId = null;
                this.GetAllMunicipalities();
            },
            deep: true
        },
        MunicipalitiesId: {
            handler() {
                this.CopyruleForm.CenterId = null;
                this.GetKednyCenter();
            },
           
        }
    },
    methods: {
        list(id) {
            this.$blockUI.Start();
            this.$http
                .GetPatientSchedule(id)
                .then((response) => {
                    this.$blockUI.Stop();
                    let List = response.data.info;
                    let ar = [];
                    List.forEach((element) => {
                        ar.push(element.day);
                    });
                    this.CopyruleForm.Day = ar;
                })
                .catch(() => {
                    this.$blockUI.Stop();
                });
        },
        GetKednyCenter() {
            this.$blockUI.Start();
            this.$http
                .GetKednyCenterByCitie(this.MunicipalitiesId)
                .then((response) => {
                    this.$blockUI.Stop();
                    this.KidnyCenter = response.data.info;
                })
                .catch(() => {
                    this.$blockUI.Stop();
                });
        },
        GetFilterById() {
            this.$blockUI.Start();
            this.$http
                .GetFilterById(this.DeviceId)
                .then((response) => {
                    this.$blockUI.Stop();
                    this.Felters = response.data.info;
                })
                .catch(() => {
                    this.$blockUI.Stop();
                });
        },
        GetCities() {
            this.$blockUI.Start();
            this.$http
                .GetCities()
                .then((response) => {
                    this.$blockUI.Stop();
                    this.citis = response.data.info;
                })
                .catch(() => {
                    this.$blockUI.Stop();
                });
        },
        GetAllMunicipalities() {
            this.$blockUI.Start();
            this.$http
                .GetMunicipalitiesByCiteisID(this.ProfileruleForm.CityId)
                .then((response) => {
                    this.$blockUI.Stop();
                    this.Municipalities = response.data.info;
                })
                .catch(() => {
                    this.$blockUI.Stop();
                });
        },
        GetNationalites() {
            this.$blockUI.Start();
            this.$http
                .GetNationalites()
                .then((response) => {
                    this.$blockUI.Stop();
                    this.Nationality = response.data.info;
                })
                .catch(() => {
                    this.$blockUI.Stop();
                });
        },
        GetDviceById() {
            this.$blockUI.Start();
            this.$http
                .GetDviceById(this.companiesId)
                .then((response) => {
                    this.$blockUI.Stop();
                    this.Devices = response.data.info;
                })
                .catch(() => {
                    this.$blockUI.Stop();
                });
        },
        GetAllCompanies() {
            this.$blockUI.Start();
            this.$http
                .GetProtocols(1, 1000000)
                .then((response) => {
                    this.$blockUI.Stop();
                    this.companies = response.data.info;
                })
                .catch(() => {
                    this.$blockUI.Stop();
                });
        },
        GetAllChronicDiseases() {
            this.$blockUI.Start();
            this.$http
                .getChronicDiseases()
                .then((response) => {
                    this.$blockUI.Stop();
                    this.chronicDiseases = response.data.info;
                })
                .catch(() => {
                    this.$blockUI.Stop();
                });
        },
        nextStep() {
            this.activeStep = this.$helper.nextStep(this.activeStep, this.formSteps);
        },
        prevStep() {
            this.activeStep = this.$helper.nextStep(this.activeStep);
        },
        submitForm(formName) {
            if (this.$helper.submitForm(this.$refs[formName])) {
                if (this.activeStep == 3) {
                    this.AddItem();
                } else {
                    this.nextStep();
                }
            }
        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
        },

        back() {
            this.$parent.state = 0;
        },

        //GetCitiesName() {
        //    //this.$blockUI.Start();
        //    //this.$http.GetCitiesName()
        //    //    .then(response => {
        //    //        this.$blockUI.Stop();
        //    //        this.Cities = response.data.info;
        //    //    })
        //    //    .catch(() => {
        //    //        this.$blockUI.Stop();
        //    //    });
        //    this.Cities =this.$helper.GetCities();
        //},

        GetMunicipalities() {
            this.$blockUI.Start();
            this.$http
                .GetAllMunicipalities()
                .then((response) => {
                    this.$blockUI.Stop();
                    this.Municipalities = response.data.info;
                })
                .catch(() => {
                    this.$blockUI.Stop();
                });
        },

        GetHospitals() {
            this.$blockUI.Start();
            this.$http
                .GetHospitalsAllName(this.MunicipalitId)
                .then((response) => {
                    this.$blockUI.Stop();
                    this.Hospitals = response.data.info;
                })
                .catch(() => {
                    this.$blockUI.Stop();
                });
        },

        GetDevicesbyCompany() {
            this.ruleForm.DeviceId = "";
            this.ruleForm.FilterTypeId = "";
            this.$blockUI.Start();
            this.$http
                .GetDevicesbyCompany(this.company)
                .then((response) => {
                    this.$blockUI.Stop();
                    this.Devices = response.data.info;
                })
                .catch(() => {
                    this.$blockUI.Stop();
                });
        },

        GetFilter() {
            this.FilterTypes = null;
            this.ruleForm.FilterTypeId = "";
            this.$blockUI.Start();
            this.$http
                .GetFilterNames(this.ruleForm.DeviceId)
                .then((response) => {
                    this.$blockUI.Stop();
                    this.FilterTypes = response.data.info;
                })
                .catch(() => {
                    this.$blockUI.Stop();
                });
        },

        //submitForm(formName) {
        //    this.$refs[formName].validate((valid) => {
        //        if (valid) {
        //            this.AddItem(formName);
        //        } else {
        //            Swal.fire({
        //                icon: 'warning',
        //                title: '..تنبيه',
        //                // text: '<strong>Something went wrong!</strong>',
        //                html:
        //                    'الرجاء التأكد من ادخال جميع البيانات',
        //                // showCloseButton: true,
        //                showCancelButton: false,
        //                //confirmButtonText: `حـفظ`,
        //                //denyButtonText: `مواق`,
        //            }).then(() => {
        //                this.resetForm(formName);
        //            });
        //            return false;
        //        }
        //    });
        //},
        //resetForm(formName) {
        //    this.$refs[formName].resetFields();
        //},
        GetMadisoneType(madisoneType) {
            switch (madisoneType) {
                case 1:
                    this.CopyruleForm.ProtocolId = 1;
                    break;
                case 2:
                case 3:
                case 4:
                    {
                        this.CopyruleForm.ProtocolId = 2;
                        this.CopyruleForm.MadisoneType = madisoneType;

                    }
                break;
            }
        },
        AddItem() {
            this.ProfileruleForm.CityId = Number(this.ProfileruleForm.CityId);
            this.ProfileruleForm.NID =
                this.ProfileruleForm.NID == ""
                    ? null
                    : Number(this.ProfileruleForm.NID);
            this.ProfileruleForm.passport =
                this.ProfileruleForm.passport == ""
                    ? null
                    : this.ProfileruleForm.passport;
            this.CopyruleForm.Center = Number(this.CopyruleForm.Center);
            this.CopyruleForm.ProtocolId = Number(this.CopyruleForm.ProtocolId);
            this.ProfileruleForm.DateOfBirth = moment(
                this.ProfileruleForm.DateOfBirth
            ).format("YYYY-MM-DDTHH:mm:ssZ");
            this.ProfileruleForm.NationalyId = Number(
                this.ProfileruleForm.NationalyId
            );
            this.ProfileruleForm.Weight = Number(
                this.ProfileruleForm.Weight
            );
            this.ProfileruleForm.Lenght = Number(
                this.ProfileruleForm.Lenght
            );

            this.ProfileruleForm.MaritalStatus = Number(
                this.ProfileruleForm.MaritalStatus
            );
            this.ContactruleForm.BloodType = Number(this.ContactruleForm.BloodType);
            this.ContactruleForm.ViralAssays = Number(
                this.ContactruleForm.ViralAssays
            );
            this.ContactruleForm.FailureCause = Number(
                this.ContactruleForm.FailureCause
            );

            this.ContactruleForm.FailureDate = moment(
                this.ContactruleForm.FailureDate
            ).format("YYYY-MM-DDTHH:mm:ssZ");
            this.ProfileruleForm.Gender = Number(this.ProfileruleForm.Gender);
            delete this.CopyruleForm.Day
            let schema = {
                ...this.ProfileruleForm,
                ...this.ContactruleForm,
                ...this.CopyruleForm,
                ...this.CopyruleForm2,
            };
            this.$blockUI.Start();
            if (this.$parent.SelectedItem) {
                schema["Id"] = this.$parent.SelectedItem?.id;
                this.$http
                    .UpdatePationt(schema)
                    .then((response) => {
                        this.$blockUI.Stop();
                        this.$parent.state = 0;
                        this.$parent.SelectedItem = null;
                        this.$parent.GetPatient(this.$parent.pageNo);

                        Swal.fire({
                            icon: "success",
                            title: "..نجـاح العملية",
                            html: response.data,
                            showCancelButton: false,
                        }).then(() => { });
                    })
                    .catch((err) => {
                        this.$blockUI.Stop();
                        return this.$helper.showWorning(err.response.data);
                    });
            } else {
                this.$http
                    .AddPationt(schema)
                    .then((response) => {
                        this.$blockUI.Stop();
                        this.$parent.state = 0;
                        this.$parent.SelectedItem = null;
                        this.$parent.GetPatient(this.$parent.pageNo);
                        Swal.fire({
                            icon: "success",
                            title: "..نجـاح العملية",
                            html: response.data,
                            showCancelButton: false,
                        }).then(() => { });
                    })
                    .catch((err) => {
                        this.$blockUI.Stop();
                        return this.$helper.showWorning(err.response.data);
                    });
            }
        },
    },
};
