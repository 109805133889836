import Swal from 'sweetalert2'
import moment from 'moment';
import flatPickr from "vue-flatpickr-component";

export default {
    name: 'Add',
    created() {
        this.Cities = this.$helper.GetCities();
        //this.GetCitiesName();

        //this.GetHospitals();
        //this.GetMunicipalities();
        ////this.GetDevices();

        //this.CheckLoginStatus();

        //this.$helper.CheckLoginStatus();
    },
    components: {
        flatPickr,
    },
    filters: {
        moment: function (date) {
            if (date === null) {
                return "فارغ";
            }
            // return moment(date).format('MMMM Do YYYY, h:mm:ss a');
            return moment(date).format('MMMM Do YYYY');
        }
    },
    data() {
        return {

            showMenu: false,
            activeClass: "js-active position-relative",
            activeStep: 0,
            formSteps: 3,

            options: [{
                value: 'Option1',
                label: 'Option1'
            }, {
                value: 'Option2',
                label: 'Option2'
            }, {
                value: 'Option3',
                label: 'Option3'
            }, {
                value: 'Option4',
                label: 'Option4'
            }, {
                value: 'Option5',
                label: 'Option5'
            }],

            config: {
                allowInput: true,
            },

            Cities: [],

            ProfileruleForm: {
                ArabicFirstName: '',
                ArabicFatherName: '',
                ArabicGrandFatherName: '',
                ArabicFamilyName: '',

                EnglishFirstName: '',
                EnglishFatherName: '',
                EnglishGrandFatherName: '',
                EnglishFamilyName: '',

                LoginName: '',
                Gender: '',
                BirthDate: '',
            },
            Profilerules: {
                ArabicFirstName: this.$helper.ArabicOnly(),
                ArabicFatherName: this.$helper.ArabicOnly(),
                ArabicGrandFatherName: this.$helper.ArabicOnly(),
                ArabicFamilyName: this.$helper.ArabicOnly(),

                EnglishFirstName: this.$helper.EnglishOnlyNotRequired(),
                EnglishFatherName: this.$helper.EnglishOnlyNotRequired(),
                EnglishGrandFatherName: this.$helper.EnglishOnlyNotRequired(),
                EnglishFamilyName: this.$helper.EnglishOnlyNotRequired(),

                LoginName: this.$helper.EnglishOnly(),
                Gender: this.$helper.Required(),
                BirthDate: this.$helper.Required(),
            },



            ContactruleForm: {
                Phone: '',
                ExtraPhone: '',
                Email: '',
                CityId: '',
                MunicipalityId: '',
                LocationId: '',
            },
            Contactrules: {
                Phone: this.$helper.NumberOnly(),
                ExtraPhone: this.$helper.NumberOnlyNotRequired(),
                Email: this.$helper.EmailNotRequierdOnly(),
                CityId: this.$helper.Required(),
                MunicipalityId: this.$helper.Required(),
                LocationId: this.$helper.Required(),
            },

            FinancialruleForm: {
                Value: 0,
            },
            Financialrules: {
                Value: this.$helper.NumberOnlyRequired(),
            },


            CourseruleForm: {
                AcademicLevelId: '',
                AcademicSpecializationId: '',
            },
            Courserules: {
                AcademicLevelId: this.$helper.Required(),
                AcademicSpecializationId: this.$helper.Required(),
            },






            ruleForm: {
                FirstName: '',
                FatherName: '',
                GrandFatherName: '',
                SirName: '',

                LoginName: '',
                BirthDate: '',

                Email: '',
                Phone: '',




                HospitalId: null,
                DeviceId: null,
                FilterTypeId: null,
                OldId: '',
                FamilyName: '',
                Nationality: '',
                Workplace: '',
                SocialStatus: null,
                Nid: '',
                Address: '',
                StartDate: '',
                BloodType: null,
                ViralTestResults: null,
                WashingCount: null,
                Resone: null,
            },
            rules: {
                HospitalId: [
                    { required: true, message: 'الرجاء اختيار  الجهة', trigger: 'blur' },
                ],
                DeviceId: [
                    { required: true, message: 'الرجاء اختيار  نوع الجهاز', trigger: 'blur' },
                ],
                FilterTypeId: [
                    { required: true, message: 'الرجاء اختيار  نوع الفلتر الخاص بالجهاز', trigger: 'blur' },
                ],
                OldId: [
                    { required: true, message: 'الرجاء ادخال رقم القيد بالمنظومة', trigger: 'blur' },
                    { required: true, pattern: /^[0-9]*$/, message: 'الرجاء إدخال ارقام فقط', trigger: 'blur' },
                ],
                FirstName: [
                    { required: true, message: 'الرجاء إدخال الاسم الاول', trigger: 'blur' },
                    { min: 3, max: 25, message: 'يجب ان يكون الطول من 3 الي 25', trigger: 'blur' },
                    { required: true, pattern: /[\u0600-\u06FF]/, message: 'الرجاء إدخال حروف العربية فقط', trigger: 'blur' }
                ],
                FatherName: [
                    { required: true, message: 'الرجاء إدخال إسم الاب', trigger: 'blur' },
                    { min: 3, max: 25, message: 'يجب ان يكون الطول من 3 الي 25', trigger: 'blur' },
                    { required: true, pattern: /[\u0600-\u06FF]/, message: 'الرجاء إدخال حروف العربية فقط', trigger: 'blur' }
                ],
                GrandFatherName: [
                    { required: true, message: 'الرجاء إدخال إسم الجد', trigger: 'blur' },
                    { min: 3, max: 25, message: 'يجب ان يكون الطول من 3 الي 25', trigger: 'blur' },
                    { required: true, pattern: /[\u0600-\u06FF]/, message: 'الرجاء إدخال حروف العربية فقط', trigger: 'blur' }
                ],
                FamilyName: [
                    { required: true, message: 'الرجاء إدخال اللقب', trigger: 'blur' },
                    { min: 3, max: 25, message: 'يجب ان يكون الطول من 3 الي 25', trigger: 'blur' },
                    { required: true, pattern: /[\u0600-\u06FF]/, message: 'الرجاء إدخال حروف العربية فقط', trigger: 'blur' }
                ],
                BirthDate: [
                    { required: true, message: 'الرجاء اختيار  تاريخ الميلاد', trigger: 'blur' },
                ],
                Nationality: [
                    { required: true, message: 'الرجاء ادخال  الجنسية', trigger: 'blur' },
                ],
                Workplace: [
                    { required: true, message: 'الرجاء ادخال  مكان العمل', trigger: 'blur' },
                ],
                SocialStatus: [
                    { required: true, message: 'الرجاء اختيار الوضع العائلي  ', trigger: 'blur' },
                ],
                Nid: [
                    { required: true, message: 'الرجاء ادخال  الرقم الوطني', trigger: 'blur' },
                    { min: 12, max: 12, message: "يجب ان يكون طول الرقم الوطني 12 رقم ", trigger: "blur", },
                    { required: true, pattern: /^[0-9]*$/, message: 'الرجاء إدخال ارقام فقط', trigger: 'blur' },

                ],
                Address: [
                    { required: true, message: 'الرجاء ادخال  مكان السكن', trigger: 'blur' },
                ],
                StartDate: [
                    { required: true, message: 'الرجاء ادخال  تاريخ البداية', trigger: 'blur' },
                ],
                BloodType: [
                    { required: true, message: 'الرجاء ادخال  فصيلة الدم', trigger: 'blur' },
                ],
                ViralTestResults: [
                    { required: true, message: 'الرجاء اختيار  نتائج التحليل الفيروسية', trigger: 'blur' },
                ],
                WashingCount: [
                    { required: true, message: 'الرجاء ادخال  عدد فترات الغسيل', trigger: 'blur' },
                ],
                Resone: [
                    { required: true, message: 'الرجاء اختيار سبب الفشل وتسبب المرض  ', trigger: 'blur' },
                ],
            },















            Municipalities: [],
            MunicipalitId: '',
            Hospitals: [],
            Devices: [],
            FilterTypes: [],
            company: '',
            loginDetails: null,

            
            
        };
    },
    methods: {


        nextStep() {
            this.activeStep = this.$helper.nextStep(this.activeStep, this.formSteps);
        },
        prevStep() {
            this.activeStep = this.$helper.nextStep(this.activeStep);
        },


        submitForm(formName) {
            if (this.$helper.submitForm(this.$refs[formName])) {
                if (this.step == 3) {
                    this.AddItem(formName);
                } else {
                    this.nextStep();
                }
            }
        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
        },

        back() {
            this.$parent.state = 0;
        },

        //GetCitiesName() {
        //    //this.$blockUI.Start();
        //    //this.$http.GetCitiesName()
        //    //    .then(response => {
        //    //        this.$blockUI.Stop();
        //    //        this.Cities = response.data.info;
        //    //    })
        //    //    .catch(() => {
        //    //        this.$blockUI.Stop();
        //    //    });
        //    this.Cities =this.$helper.GetCities();
        //},












        GetMunicipalities() {
            this.$blockUI.Start();
            this.$http.GetAllMunicipalities()
                .then(response => {
                    this.$blockUI.Stop();
                    this.Municipalities = response.data.info;
                })
                .catch(() => {
                    this.$blockUI.Stop();
                });

        },

        GetHospitals() {
            this.$blockUI.Start();
            this.$http.GetHospitalsAllName(this.MunicipalitId)
                .then(response => {
                    this.$blockUI.Stop();
                    this.Hospitals = response.data.info;
                })
                .catch(() => {
                    this.$blockUI.Stop();
                });

        },

        GetDevicesbyCompany() {
            this.ruleForm.DeviceId = '';
            this.ruleForm.FilterTypeId = '';
            this.$blockUI.Start();
            this.$http.GetDevicesbyCompany(this.company)
                .then(response => {
                    this.$blockUI.Stop();
                    this.Devices = response.data.info;
                })
                .catch(() => {
                    this.$blockUI.Stop();
                });

        },

        GetFilter() {
            this.FilterTypes = null;
            this.ruleForm.FilterTypeId = '';
            this.$blockUI.Start();
            this.$http.GetFilterNames(this.ruleForm.DeviceId)
                .then(response => {
                    this.$blockUI.Stop();
                    this.FilterTypes = response.data.info;
                })
                .catch(() => {
                    this.$blockUI.Stop();
                });

        },




        //submitForm(formName) {
        //    this.$refs[formName].validate((valid) => {
        //        if (valid) {
        //            this.AddItem(formName);
        //        } else {
        //            Swal.fire({
        //                icon: 'warning',
        //                title: '..تنبيه',
        //                // text: '<strong>Something went wrong!</strong>',
        //                html:
        //                    'الرجاء التأكد من ادخال جميع البيانات',
        //                // showCloseButton: true,
        //                showCancelButton: false,
        //                //confirmButtonText: `حـفظ`,
        //                //denyButtonText: `مواق`,
        //            }).then(() => {
        //                this.resetForm(formName);
        //            });
        //            return false;
        //        }
        //    });
        //},
        //resetForm(formName) {
        //    this.$refs[formName].resetFields();
        //},
      
        AddItem(formName) {

            if (!this.ruleForm.FilterTypeId)
                this.ruleForm.FilterTypeId = 0;

            this.$blockUI.Start();
            this.$http.AddApplications(this.ruleForm)
                .then(response => {
                    this.$blockUI.Stop();
                    this.ruleForm.FilterTypeId = null;
                    this.resetForm(formName);
                    Swal.fire({
                        icon: 'success',
                        title: '..نجـاح العملية',
                        // text: '<strong>Something went wrong!</strong>',
                        html:
                            response.data,
                        // showCloseButton: true,
                        showCancelButton: false,
                        //confirmButtonText: `حـفظ`,
                        //denyButtonText: `مواق`,
                    }).then(() => {
                       
                    });
                })
                .catch((err) => {
                    this.$blockUI.Stop();
                    this.$notify({
                        title: 'خطأ بعملية الاضافة',
                        dangerouslyUseHTMLString: true,
                        type: 'error',
                        message: err.response.data
                    });
                });
        }


        

    }
}
