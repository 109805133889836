import Swal from "sweetalert2";
import moment from "moment";
import Add from "./Add/Add.vue";
import { excelParser } from "./excel-parser";
export default {
  name: "AddUser",
  created() {
    this.GetPatient();
    this.GetAllCompanies();
    this.GetCities();
    this.GetDeviceName();
  },
  components: {
    Add: Add,
  },
  filters: {
    moment: function(date) {
      if (date === null) {
        return "فارغ";
      }
      return moment(date).format("MMMM Do YYYY");
    },
  },
  data() {
    return {
      Date2: "",
      ProtocolId: "",
      item: { protocol: { protocolMadisone: [], disease: "" } },
      AddViewDilogRequest: false,
      companyId: "",
      centerId: "",
      MunicipalitiesId: "",
      CityId2: "",
      KidnyCenter: [],
      DevicesName: [],
      List: [],
      Phone: "",
      Relations: "",
      image: "",
      file: "",
      Felters: [],
      FeltersId: null,
      from: "",
      day: "",
      to: "",
      FileNumber: "",
      NationalId: "",
      FileName: "",
      Phones: [],
      pageNo: 1,
      pageSize: 10,
      pages: 0,
      state: 0,
      SelectedItem: "",
      Patient: [],
      citis: [],
      Devices: [],
      AllDevices: [],
      DevicesTable: [],
      Attachments: [],
      PatientId: null,
      companies: [],
      Municipalities: [],
      companiesId: "",
      DeviceId: "",
      Kid: "",
      ruleForm2: {
        PatientId: "",
        DeviceId: "",
        Count: 0,
      },
      rules2: {
        PatientId: this.$helper.Required(),
        DeviceId: this.$helper.Required(),
        Count: this.$helper.Required(),
      },
      ruleForm: {
        Id: null,
        MunicipalityId: "",
        ArabicName: "",
        EnglishName: "",
      },
      rules: {
        MunicipalityId: this.$helper.Required(),
        ArabicName: this.$helper.ArabicOnly(),
        EnglishName: this.$helper.Required(),
      },
    };
  },
  methods: {
    moment(date){
     return moment(date).format('MMMM Do YYYY')
    },
    reject(item){
      this.$blockUI.Start();
      this.$http
        .rejectPatientSchedule(item.id)
        .then(() => {
          this.$blockUI.Stop();
          Swal.fire({
            icon: "success",
            title: "تم الحفظ بنجاح",
            showCancelButton: false,
          }).then(() => {});
          this.GetPatient();
          document.getElementById("close22").click();
        })
        .catch((err) => {
          this.$blockUI.Stop();
          this.$helper.showWorning(err.response.data);
        });
    },
    Axept(item){
      this.$blockUI.Start();
      this.$http
        .AcceptPatientSchedule(item.id)
        .then(() => {
          this.$blockUI.Stop();
          Swal.fire({
            icon: "success",
            title: "تم الحفظ بنجاح",
            showCancelButton: false,
          }).then(() => {});
          this.GetPatient();
          document.getElementById("close22").click();
        })
        .catch((err) => {
          this.$blockUI.Stop();
          this.$helper.showWorning(err.response.data);
        });
    },
    editNow(item){
      let schema = {
        Id:item.id,
        PatintId: item.patientId,
        ProtocolId: Number(item.protocol.protocolId),
        Date: moment(item.date)
          .format("YYYY-MM-DDTHH:mm:ssZ"),
      };

      this.$blockUI.Start();
      this.$http
        .updatePatientSchedule(schema)
        .then(() => {
          this.$blockUI.Stop();
          Swal.fire({
            icon: "success",
            title: "تم الحفظ بنجاح",
            showCancelButton: false,
          }).then(() => {});
          this.GetPatient();
          document.getElementById("close22").click();
          this.PatientId = null;
          this.ProtocolId = null;
          this.Date = null;
        })
        .catch((err) => {
          this.$blockUI.Stop();
          this.$helper.showWorning(err.response.data);
        });
    },

    AddPationtscajul() {
      let schema = {
        PatintId: this.PatientId,
        ProtocolId: Number(this.ProtocolId),
        Date: moment(this.Date2)
          .format("YYYY-MM-DDTHH:mm:ssZ"),
      };

      this.$blockUI.Start();
      this.$http
        .AddPatientSchedule(schema)
        .then(() => {
          this.$blockUI.Stop();
          Swal.fire({
            icon: "success",
            title: "تم الحفظ بنجاح",
            showCancelButton: false,
          }).then(() => {});
          this.GetPatient();
          document.getElementById("close22").click();
          this.PatientId = null;
          this.ProtocolId = null;
          this.Date = null;
        })
        .catch(() => {
          this.$blockUI.Stop();
        });
    },
    AddChangeRequestruleForm() {},
    showPationt(item) {
      this.item = item;
      this.AddViewDilogRequest = true;
    },
    exportData() {
      let dataModefide = [];
      this.Patient.forEach((element) => {
        let schema = {
          id: element.id,
          from: element.city?.name,
          city_kidney_Center: element.center?.municipality?.city?.name,
          municipality_center: element.center?.municipality?.name,
          center: element.center?.arabicName,
          company: element.filter?.device?.company?.name,
          device: element.filter?.device?.name,
          filter: element.filter?.name,
          nationality: element.nationality?.name,
          image: element.image,
          nid: String(element.nid),
          fileNumber: element.fileNumber,
          firstName: element.firstName,
          middleName: element.middleName,
          grandfatherName: element.grandfatherName,
          lastName: element.lastName,
          name: element.name,
          gender: element.gender == 1 ? "ذكر" : "انثى",
          dateOfBirth: element.dateOfBirth?.split("T")[0],
          workplace: element.workplace,
          maritalStatus:
            element.maritalStatus == 1
              ? "اعزب"
              : element.maritalStatus == 2
              ? "متزوج"
              : "ارمل",
          address: element.address,
          bloodType:
            element.bloodType == 1
              ? "O+"
              : element.bloodType == 2
              ? "O-"
              : element.bloodType == 3
              ? "A+"
              : element.bloodType == 4
              ? "A-"
              : element.bloodType == 5
              ? "B+"
              : element.bloodType == 6
              ? "B-"
              : element.bloodType == 7
              ? "AB+"
              : element.bloodType == 7
              ? "AB-"
              : "غير متوفر",
          viralAssays:
            element.viralAssays == 1
              ? "Negative"
              : element.viralAssays == 2
              ? "C-Postive"
              : element.viralAssays == 3
              ? "B-Postive"
              : element.viralAssays == 4
              ? "HIV"
              : "غير متوفر",
          kidneyFailureDate: element.kidneyFailureDate?.split("T")[0],
          kidneyFailureCause:
            element.kidneyFailureCause == 1
              ? "وراثي"
              : element.kidneyFailureCause == 2
              ? "ضغظ دم"
              : element.kidneyFailureCause == 3
              ? "سكرى"
              : element.kidneyFailureCause == 4
              ? "مسالك"
              : element.kidneyFailureCause == 5
              ? "اخرى"
              : "غير متوفر",
          createdBy: element.createdBy,
          createdOn: element.createdOn?.split("T")[0],
        };
        dataModefide.push(schema);
      });
      excelParser().exportDataFromJSON(dataModefide, null, null);
    },
      GetByPatientInfo() {
      this.$blockUI.Start();
        return this.$http
        .GetByPatientInfo(this.FileNumber, this.NationalId)
        .then((response) => {
          this.$blockUI.Stop();
          this.Patient = response.data.info;
          this.pages = response.data.count;
        })
        .catch(() => {
          this.$blockUI.Stop();
        });
    },
    refresh() {
      this.centerId = "";
      this.MunicipalitiesId = "";
      this.CityId2 = "";
      this.CenterId = "";
      this.FileNumber = null;
      this.NationalId = null;
      this.GetPatient();
    },

    // getManticiplintKednyCenter(){
    //   this.$blockUI.Start();
    //   this.$http
    //     .getManticiplintKednyCenter(this.MunicipalitiesId)
    //     .then((response) => {
    //       this.$blockUI.Stop();
    //       this.Patient = response.data.info;
    //       this.pages  = response.data.count
    //     })
    //     .catch(() => {
    //       this.$blockUI.Stop();
    //     });
    // },

    GetKednyCenter() {
      // this.getManticiplintKednyCenter()
      this.centerId = null;
        this.$blockUI.Start();
        this.GetPatient();
      this.$http
        .GetKednyCenterByCitie(this.MunicipalitiesId)
        .then((response) => {
          this.$blockUI.Stop();
          this.KidnyCenter = response.data.info;
        })
        .catch(() => {
          this.$blockUI.Stop();
        });
    },
    convertDate(date) {
      if (date === null) {
        return "فارغ";
      }
      return moment(date).format("YYYY-MM-DDTHH:mm:ssZ");
      // return moment(date).format("MMMM Do YYYY");
    },
    AddPatientSchedule() {
      if (!this.from || !this.FeltersId || !this.to || !this.day) {
        return this.$helper.showWorning("الرجاء تعبئة كافة الحقول");
      }
      let bodyObject = {
        PatintId: this.PatientId,
        Day: Number(this.day),
        FilterId: Number(this.FeltersId),
        from: this.convertDate(Date(this.from)),
        to: this.convertDate(Date(this.to)),
      };
      this.$blockUI.Start();
      this.$http
        .AddPatientSchedule(bodyObject)
        .then(() => {
          this.$blockUI.Stop();
          Swal.fire({
            icon: "success",
            title: "تم الحفظ بنجاح",
            showCancelButton: false,
          }).then(() => {});
          document.getElementById("ListClose").click();
        })
        .catch((err) => {
          this.$blockUI.Stop();
          return this.$helper.showWorning(err.response.data);
        });
    },
    GetFilterById() {
      this.$blockUI.Start();
      this.$http
        .GetFilterById(this.ruleForm2.DeviceId)
        .then((response) => {
          this.$blockUI.Stop();
          this.Felters = response.data.info;
        })
        .catch(() => {
          this.$blockUI.Stop();
        });
    },

    GetDeviceName() {
      this.$blockUI.Start();
      this.$http
        .GetAllDevices()
        .then((response) => {
          this.$blockUI.Stop();
          this.Devices = response.data.info;
        })
        .catch(() => {
          this.$blockUI.Stop();
        });
    },

    GetDviceById(index) {
      this.$blockUI.Start();
      this.$http
        .GetDviceById(this.companyId)
        .then((response) => {
          this.$blockUI.Stop();
          this.AllDevices[index] = response.data.info;
        })
        .catch(() => {
          this.$blockUI.Stop();
        });
    },
    GetAllCompanies() {
      this.$blockUI.Start();
      this.$http
        .GetProtocols(1, 10000)
        .then((response) => {
          this.$blockUI.Stop();
          this.companies = response.data.info;
        })
        .catch(() => {
          this.$blockUI.Stop();
        });
    },
    edit(item) {
      this.state = 1;
      this.SelectedItem = item;
    },

    attachment(id) {
      this.PatientId = id;
      this.$blockUI.Start();
      this.$http
        .GetAttachments(id)
        .then((response) => {
          this.$blockUI.Stop();
          this.Attachments = response.data.info;
          document.getElementById("attachment").click();
        })
        .catch(() => {
          this.$blockUI.Stop();
        });
    },

    Image(item) {
      this.PatientId = item.id;
      this.image = item.image;
      document.getElementById("Image").click();
    },

    phone(id) {
      document.getElementById("phone").click();
      this.PatientId = id;
      this.$blockUI.Start();
      this.$http
        .GetPhones(id)
        .then((response) => {
          this.$blockUI.Stop();
          this.Phones = response.data.info;
          document.getElementById("attachment").click();
        })
        .catch(() => {
          this.$blockUI.Stop();
        });
    },

    list(id) {
      this.PatientId = id;
      document.getElementById("list").click();

      this.$blockUI.Start();
      this.$http
        .GetPatientSchedule(id)
        .then((response) => {
          this.$blockUI.Stop();
          this.List = response.data.info;
        })
        .catch(() => {
          this.$blockUI.Stop();
        });
    },

    
    AddPhones() {
      if (!this.Phone || !this.Relations) {
        return this.$helper.showWorning("الرجاء تعبئة كافة الحقول");
      }
      if (this.Phone.length != 10) {
        return this.$helper.showWorning(
          "الرجاء إدخال الرقم الهاتف بطريقة صحيحة"
        );
      }
      let bodyObject = {
        PatintId: this.PatientId,
        Phone: this.Phone,
        Relations: this.Relations,
      };
      this.$blockUI.Start();
      this.$http
        .AddPhones(bodyObject)
        .then(() => {
          this.$blockUI.Stop();
          Swal.fire({
            icon: "success",
            title: "تم الحفظ بنجاح",
            showCancelButton: false,
          }).then(() => {});
          this.GetPatient();
          document.getElementById("PhoneClose").click();
        })
        .catch((err) => {
          this.$blockUI.Stop();
          return this.$helper.showWorning(err.response.data);
        });
    },
    AddImage() {
      if (!this.file) {
        return this.$helper.showWorning("الرجاء تعبئة كافة الحقول");
      }
      let bodyObject = {
        PatientId: this.PatientId,
        Name: String(Math.random()),
        fileBase64: this.file,
        Type: ".png",
      };
      this.$blockUI.Start();
      this.$http
        .AddImage(bodyObject)
        .then(() => {
            this.$blockUI.Stop();
            document.getElementById("peronal-image-input").value = null;
          Swal.fire({
            icon: "success",
            title: "تم الحفظ بنجاح",
            showCancelButton: false,
          }).then(() => {});
          this.GetPatient();
          document.getElementById("ImageClose").click();
        })
        .catch(() => {
          this.$blockUI.Stop();
        });
    },
      clearFileInput() {
          this.FileName = null;
          this.file = null;
          this.PatientId = null;
          document.getElementById('patient_file').value = null;
      },
    AddAttachment() {
      if (!this.FileName || !this.file) {
        return this.$helper.showWorning("الرجاء تعبئة كافة الحقول");
      }
      let bodyObject = {
        PatientId: this.PatientId,
        Name: this.FileName,
        fileBase64: this.file,
        Type: ".png",
      };
      this.$blockUI.Start();
      this.$http
        .AddAttachment(bodyObject)
        .then(() => {
            this.$blockUI.Stop();
            this.FileName = null;
            this.file = null;
            this.PatientId = null;
            document.getElementById('patient_file').value = null;

          Swal.fire({
            icon: "success",
            title: "تم الحفظ بنجاح",
            showCancelButton: false,
          }).then(() => {});
          this.attachment(this.PatientId);
          // this.PatientId = null
          document.getElementById("attachmentClose").click();
        })
        .catch(() => {
          this.$blockUI.Stop();
        });
    },
    FileChanged(e) {
      var files = e.target.files;
      if (files.length <= 0) {
        return;
      }
      if (files[0].type !== "image/jpeg" && files[0].type !== "image/png") {
        this.$helper.showWorning("عفوا يجب انت تكون الصورة من نوع JPG ,PNG");
        this.file = null;
        return;
      }
      var $this = this;
      var reader = new FileReader();
      reader.onload = function() {
        $this.file = reader.result;
      };
      reader.onerror = function() {
        $this.file = null;
      };
      reader.readAsDataURL(files[0]);
    },

    submitForm(formName) {
      if (this.$helper.submitForm(this.$refs[formName])) {
        if (this.ruleForm.Id) {
          return this.update(formName);
        }
        this.Add(formName);
      }
    },

    submitForm2(formName) {
      if (this.$helper.submitForm(this.$refs[formName])) {
        this.AddDevice(formName);
      }
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    update(formName) {
      this.$blockUI.Start();
      this.$http
        .UpdatePatient(this.ruleForm)
        .then(() => {
          this.$blockUI.Stop();
          Swal.fire({
            icon: "success",
            title: "تم الحفظ بنجاح",
            showCancelButton: false,
          }).then(() => {});
          this.GetPatient();
          document.getElementById("close").click();
          this.resetForm(formName);
          this.ruleForm.Id = null;
        })
        .catch(() => {
          this.$blockUI.Stop();
        });
    },
    Add(formName) {
      this.$blockUI.Start();
      this.$http
        .AddPatient(this.ruleForm)
        .then(() => {
          this.$blockUI.Stop();
          Swal.fire({
            icon: "success",
            title: "تم الحفظ بنجاح",
            showCancelButton: false,
          }).then(() => {});
          this.GetPatient();
          document.getElementById("close").click();
          this.resetForm(formName);
          this.ruleForm.Id = null;
        })
        .catch(() => {
          this.$blockUI.Stop();
        });
    },
    AddItemPage() {
      this.state = 1;
      this.SelectedItem = null;
    },
    show(item) {
      this.ruleForm2.PatientId = item.id;
      this.$blockUI.Start();
      this.$http
        .ShowDevices(item.id)
        .then((res) => {
          this.Kid = item.id;
          this.$blockUI.Stop();
          this.DevicesTable = res.data.info;
          document.getElementById("openDevices").click();
        })
        .catch(() => {
          this.$blockUI.Stop();
        });
    },

    DeletePhones(id) {
      Swal.fire({
        title: "هـل انت متأكد من عملية الحذف ؟",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `تأكيد العملية`,
        denyButtonText: `الغاء العملية`,
      }).then((result) => {
        if (result.isConfirmed) {
          this.$blockUI.Start();
          this.$http
            .DeletePhones(id)
            .then((response) => {
              this.$blockUI.Stop();
              this.FormPorgress = 100;
              Swal.fire({
                icon: "success",
                title: "..نجـاح العملية",
                html: response.data,
                showCancelButton: false,
                //confirmButtonText: `حـفظ`,
              }).then(() => {
                this.$blockUI.Stop();
              });
            })
            .catch((err) => {
              this.$blockUI.Stop();
              this.$helper.showWorning(err.response.data);
            });
          return;
        }
      });
    },
    UpdatePationtScagualList() {
      this.$blockUI.Start();
      this.$http
        .UpdatePationtScagualList(this.List)
        .then(() => {
          this.$blockUI.Stop();
          Swal.fire({
            icon: "success",
            title: "تم الحفظ بنجاح",
            showCancelButton: false,
          }).then(() => {});
          this.GetPatient();
          document.getElementById("close").click();
          this.ruleForm.Id = null;
        })
        .catch(() => {
          this.$blockUI.Stop();
        });
    },
    DeleteAttachment(id) {
      Swal.fire({
        title: "هـل انت متأكد من عملية الحذف ؟",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `تأكيد العملية`,
        denyButtonText: `الغاء العملية`,
      }).then((result) => {
        if (result.isConfirmed) {
          this.$blockUI.Start();
          this.$http
            .DeleteAttachment(id)
            .then((response) => {
              this.$blockUI.Stop();
              this.FormPorgress = 100;
              Swal.fire({
                icon: "success",
                title: "..نجـاح العملية",
                html: response.data,
                showCancelButton: false,
                //confirmButtonText: `حـفظ`,
              }).then(() => {
                this.$blockUI.Stop();
                this.attachment(this.PatientId);
              });
            })
            .catch((err) => {
              this.$blockUI.Stop();
              this.$helper.showWorning(err.response.data);
            });
          return;
        }
      });
    },

    deleteDevice(id) {
      Swal.fire({
        title: "هـل انت متأكد من عملية الحذف ؟",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `تأكيد العملية`,
        denyButtonText: `الغاء العملية`,
      }).then((result) => {
        if (result.isConfirmed) {
          this.$blockUI.Start();
          this.$http
            .deleteDeviceKidny(id)
            .then((response) => {
              this.$blockUI.Stop();
              this.FormPorgress = 100;
              Swal.fire({
                icon: "success",
                title: "..نجـاح العملية",
                html: response.data,
                showCancelButton: false,
                //confirmButtonText: `حـفظ`,
              }).then(() => {
                this.$blockUI.Stop();
                this.show(this.Kid);
              });
            })
            .catch((err) => {
              this.$blockUI.Stop();
              this.$helper.showWorning(err.response.data);
            });
          return;
        }
      });
    },
    GetCities() {
      this.$blockUI.Start();
      this.$http
        .GetCities()
        .then((response) => {
          this.$blockUI.Stop();
          this.citis = response.data.info;
        })
        .catch(() => {
          this.$blockUI.Stop();
        });
    },

    Getmunicipalities() {
      this.centerId = null;
      this.MunicipalitiesId = null;
        this.$blockUI.Start();
        this.GetPatient();
      this.$http
        .GetMunicipalitiesByCiteisID(this.CityId2)
        .then((response) => {
          this.$blockUI.Stop();
          this.Municipalities = response.data.info;
        })
        .catch(() => {
          this.$blockUI.Stop();
        });
    },
    AddDevice(formName) {
      this.$blockUI.Start();
      this.ruleForm2.Count = Number(this.ruleForm2.Count);
      this.$http
        .AddDeviceToKindycenter(this.ruleForm2)
        .then(() => {
          this.$blockUI.Stop();
          Swal.fire({
            icon: "success",
            title: "تم الحفظ بنجاح",
            showCancelButton: false,
          }).then(() => {});
          this.GetPatient();
          document.getElementById("close2").click();
          this.resetForm2(formName);
          this.ruleForm2.PatientId = null;
        })
        .catch(() => {
          this.$blockUI.Stop();
        });
      },




      GetPatient(pageNo) {
          this.pageNo = pageNo;
          if (this.pageNo === undefined) {
              this.pageNo = 1;
          }
          this.$blockUI.Start();

          //if (this.CityId2) {
          //    return this.$http
          //        .GetPatinetByCityId(this.pageNo, this.pageSize,this.CityId2)
          //        .then((response) => {
          //            this.$blockUI.Stop();
          //            this.Patient = response.data.info;
          //            this.pages = response.data.count;
          //        })
          //        .catch(() => {
          //            this.$blockUI.Stop();
          //        });
          //}

          //if (this.MunicipalitiesId) {
          //    return this.$http
          //        .GetMunicipalitiesId(this.pageNo, this.pageSize,this.MunicipalitiesId)
          //        .then((response) => {
          //            this.$blockUI.Stop();
          //            this.Patient = response.data.info;
          //            this.pages = response.data.count;
          //        })
          //        .catch(() => {
          //            this.$blockUI.Stop();
          //        });
          //}


          //if (this.centerId) {
          //    return this.$http
          //        .GetByIdKidneyCenterId(this.pageNo, this.pageSize,this.centerId)
          //        .then((response) => {
          //            this.$blockUI.Stop();
          //            this.Patient = response.data.info;
          //            this.pages = response.data.count;
          //        })
          //        .catch(() => {
          //            this.$blockUI.Stop();
          //        });
          //}


          this.$http
              .GetPatient(this.pageNo, this.pageSize, this.CityId2, this.MunicipalitiesId, this.centerId)
              .then((response) => {
                  this.$blockUI.Stop();
                  this.Patient = response.data.info;
                  this.pages = response.data.count;
              })
              .catch(() => {
                  this.$blockUI.Stop();
              });


      },





    DeletePatientSchedule(id) {
      Swal.fire({
        title: "هـل انت متأكد من عملية الحذف ؟",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `تأكيد العملية`,
        denyButtonText: `الغاء العملية`,
      }).then((result) => {
        if (result.isConfirmed) {
          this.$blockUI.Start();
          this.$http
            .DeletePatientSchedule(id)
            .then((response) => {
              this.$blockUI.Stop();
              this.FormPorgress = 100;
              Swal.fire({
                icon: "success",
                title: "..نجـاح العملية",
                html: response.data,
                showCancelButton: false,
                //confirmButtonText: `حـفظ`,
              }).then(() => {
                this.$blockUI.Stop();
                document.getElementById("ListClose").click();
              });
            })
            .catch((err) => {
              this.$blockUI.Stop();
              this.$helper.showWorning(err.response.data);
            });
          return;
        }
      });
    },
    deleteItem(id) {
      Swal.fire({
        title: "هـل انت متأكد من عملية الحذف ؟",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `تأكيد العملية`,
        denyButtonText: `الغاء العملية`,
      }).then((result) => {
        if (result.isConfirmed) {
          this.$blockUI.Start();
          this.$http
            .deletePatient(id)
            .then((response) => {
              this.$blockUI.Stop();
              this.FormPorgress = 100;
              Swal.fire({
                icon: "success",
                title: "..نجـاح العملية",
                html: response.data,
                showCancelButton: false,
                //confirmButtonText: `حـفظ`,
              }).then(() => {
                this.$blockUI.Stop();
                this.GetPatient();
              });
            })
            .catch((err) => {
              this.$blockUI.Stop();
              this.$helper.showWorning(err.response.data);
            });
          return;
        }
      });
      },
      getGender(genderId) {
          switch (genderId) {
              case 1:
                  return "ذكر";
              case 2:
                  return "أنثى";
              default:
                  return "";
          }
      }

    // CheckLoginStatus() {
    //     try {
    //         this.loginDetails = JSON.parse(localStorage.getItem('currentUser-client'));
    //         if (this.loginDetails == null) {
    //             window.location.href = '/Login';
    //         }
    //     } catch (error) {
    //         window.location.href = '/Login';
    //     }
    // },
  },
};
