import Vue from 'vue';
import VueI18n from 'vue-i18n'
import VueRouter from 'vue-router';
import ElementUI from 'element-ui';
import Vuetify from 'vuetify'
import locale from 'element-ui/lib/locale/lang/en'
import BlockUIService from './Shared/BlockUIService.js';
import App from './App.vue';
import Layout from './components/Layout/Layout.vue';
import Login from './components/Login/Login.vue';
import Home from './components/Home/Home.vue';
import DataService from './Shared/DataService';
import Helper from './Shared/Helper';
import Profile from './components/Users/EditUsersProfile/EditUsersProfile.vue'
import Users from './components/Users/Users.vue';
import AddUsers from './components/Users/AddUsers/AddUsers.vue';
import ChangePassword from './components/Users/ChangePassword/ChangePassword.vue';
import patientDay from './components/patientDay/AddDevices.vue'





import municipalities from './components/municipalities/municipalities.vue';
import KidneyCenters from './components/KidneyCenters/KidneyCenters.vue';
import company from './components/company/company.vue';
import AddDevices from './components/AddDevices/AddDevices.vue'
import needs from './components/needs/needs.vue';
import Consumption from './components/Consumption/Consumption.vue';
import reports from './components/Reports/Reports.vue';
import PatientTransferRequest from './components/PatientTransferRequest/PatientTransferRequest.vue';
import Inventory from './components/inventory/inventory.vue'
import felters from "./components/felters/filters.vue"
import PatientDailyUsed from "./components/PatientDailyUsed/PatientDailyUsed.vue"
import PatientUsed from "./components/PatientUsed/PatientUsed.vue"
import patient from "./components/patient/patient.vue"
import Disease from './components/Disease/Disease.vue';
import Madisons from './components/Madisons/Madisons.vue';
import PatientRequierd from './components/PatientRequierd/PatientRequierd.vue';
import Cities from './components/Cities/Cities.vue';







import Applications from './components/Applications/Applications.vue';
import AddApplications from './components/Applications/Add/Add.vue';


import VueEllipseProgress from 'vue-ellipse-progress';

Vue.use(VueEllipseProgress);

Vue.use(Vuetify);
Vue.use(VueI18n);
Vue.use(VueRouter);
Vue.use(ElementUI, { locale });

Vue.config.productionTip = false;

Vue.prototype.$http = DataService;
Vue.prototype.$blockUI = BlockUIService;
Vue.prototype.$helper = Helper;

export const eventBus = new Vue();

//const i18n = new VueI18n({
//    locale: 'ar', // set locale
//    messages, // set locale messages
//})

const router = new VueRouter({
  mode: "history",
  base: __dirname,
  linkActiveClass: "active",
  routes: [
    {
      path: "/Login",
      component: Login,
    },
    {
      path: "/",
      component: App,
      children: [
        {
          path: "",
          component: Layout,
          children: [
            { path: "", component: Home },
            { path: "Profile", component: Profile },
            { path: "Users", component: Users },
            { path: "AddUsers", component: AddUsers },
            { path: "ChangePassword", component: ChangePassword },
            { path: "KidneyCenters", component: KidneyCenters },
            { path: "municipalities", component: municipalities },
            { path: "company", component: company },
            { path: "AddDevices", component: AddDevices },
            { path: "needs", component: needs },
            { path: "Consumption", component: Consumption },
            { path: "patientDay", component: patientDay },
              { path: "Cities", component: Cities },
              { path: "Disease", component: Disease },
              { path: "Madisons", component: Madisons },

            { path: "reports", component: reports },
            {
              path: "PatientTransferRequest",
              component: PatientTransferRequest,
            },
            {
              path: "PatientDailyUsed",
              component: PatientDailyUsed,
            },{
              path: "PatientUsed",
              component: PatientUsed,
            },
            

            { path: "Inventory", component: Inventory },
            { path: "Filters", component: felters },
            { path: "patient", component: patient },

              { path: "PatientRequierd", component: PatientRequierd },
            { path: "Applications", component: Applications },
            { path: "Users", component: Users },
            {
              path: "Applications/AddApplications",
              component: AddApplications,
            },
          ],
        },
      ],
    },
  ],
});

Vue.filter("toUpperCase", function(value) {
  if (!value) return "";
  return value.toUpperCase();
});

new Vue({
  router,
  render: (h) => {
    return h(App);
  },
}).$mount("#cpanel-management");
