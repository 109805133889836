export default {
  name: "AddUser",
  created() {
    this.MunicipalitieId = this.$parent.EditUsersObj?.municipalitieId;
    this.form.Name = this.$parent.EditUsersObj?.name;
    this.form.LoginName = this.$parent.EditUsersObj?.loginName;
    this.form.Phone = this.$parent.EditUsersObj?.phone;
    this.form.Email = this.$parent.EditUsersObj?.email;
    this.form.Id = this.$parent.EditUsersObj?.id;
    this.form.UserType = this.$parent.EditUsersObj?.userType;
    this.form.HospitalId = this.$parent.EditUsersObj?.centerId;

      if (this.$parent.EditUsersObj?.municipalityId) {
          this.MunicipalitId = this.$parent.EditUsersObj?.municipalityId;
          this.GetHospitalsName();
      }
    this.GetMunicipalities();
    this.UserType = [
      {
        id: 1,
        name: "مـدير النظام",
      },
      {
        id: 2,
        name: "مـوظف",
      },
    ];

    this.GetHospitalsName();
  },
  data() {
    return {
      Municipalities: [],
      MunicipalitId: "",
      pageNo: 1,
      pageSize: 10,
      pages: 0,
      UserType: [],
      Hospitals: [],
        form: {
        Id: null,
        LoginName: "",
        Password: null,
        Name: "",
        UserType: "",
        HospitalId: null,
        Email: "",
        Phone: "",
        ImageName: "Test",
        fileBase64: "Test",
        ImageType: ".png",
      },
      ConfimPassword: "",
    };
    },
    watch: {
        MunicipalitId(newValue) {
            if (newValue) {
                this.GetHospitalsName();
            }
        }
    },
  methods: {
    GetMunicipalities() {
      this.$blockUI.Start();
      this.$http
        .GetMunicipalities(1, 1000000)
        .then((response) => {
          this.$blockUI.Stop();
          this.Municipalities = response.data.info;
        })
        .catch(() => {
          this.$blockUI.Stop();
        });
    },

    UserTypeSearch() {
      this.form.HospitalId = "";
    },

    GetHospitalsName() {
      this.$blockUI.Start();
      this.$http
        .GetKednyCenterByCitie(this.MunicipalitId)
        .then((response) => {
          this.$blockUI.Stop();
          this.Hospitals = response.data.info;
        })
        .catch(() => {
          this.$blockUI.Stop();
        });
    },

    Back() {
      this.$parent.state = 0;
    },
    resetform() {
      this.form.LoginName = "";
      this.form.Password = null;
      this.form.Name = "";
      this.form.UserType = "";
      this.form.Email = "";
      this.form.Phone = "";
      this.ConfimPassword = "";
      this.HospitalId = "";
    },

    validEmail: function(email) {
      var re = /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    validLoginName: function(LoginName) {
      var login = /^[a-zA-Z]{0,40}$/;
      return login.test(LoginName);
    },
    validFullName: function(Name) {
      var loginArabic = /^[\u0621-\u064A\u0660-\u0669 ]+$/;
      return loginArabic.test(Name);
    },

    validPassword: function(Password) {
      var PasswordT = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]){8,}.*$/;

      return PasswordT.test(Password);
    },
    validPhone: function(Phone) {
      var mobileRegex = /^9[1|2|3|4|5][0-9]{7}$/i;

      return mobileRegex.test(Phone);
    },

    Save() {
      this.$blockUI.Start();

      if (!this.form.LoginName) {
        this.$blockUI.Stop();
        this.$message({
          type: "error",
          message: "الـرجاء إدخال اسم الدخول",
        });
        return;
      } else if (!this.validLoginName(this.form.LoginName)) {
        this.$blockUI.Stop();
        this.$message({
          type: "error",
          message:
            "الرجاء إدخال اسم الدخول بطريقه صحيحه بحيث لايوجد فارغات او رموز",
        });
        return;
      }

      if (!this.form.Name) {
        this.$blockUI.Stop();
        this.$message({
          type: "error",
          message: "الرجاء إدخال الاسم التلاثي ",
        });
        return;
      } else if (!this.validFullName(this.form.Name)) {
        this.$blockUI.Stop();
        this.$message({
          type: "error",
          message: "الرجاء إدخال الاسم التلاثي بطريقه صحيحه ",
        });
        return;
      }

      if (!this.form.Email) {
        this.$blockUI.Stop();
        this.$message({
          type: "error",
          message: "الرجاء إدخال البريد الإلكتروني ",
        });
        return;
      } else if (!this.validEmail(this.form.Email)) {
        this.$blockUI.Stop();
        this.$message({
          type: "error",
          message: "الرجاء إدخال البريد الإلكتروني بطريقه صحيحه ",
        });
        return;
      }

      if (!this.form.Phone) {
        this.$blockUI.Stop();
        this.$message({
          type: "error",
          message: "الرجاء رقم الهاتف ",
        });
        return;
      } else if (!this.validPhone(this.form.Phone)) {
        this.$blockUI.Stop();
        this.$message({
          type: "error",
          message: "الرجاء إدخال رقم الهاتف  بطريقه صحيحه ",
        });
        return;
      }

        if (!this.form.Id && !this.form.Password) {
        this.$blockUI.Stop();
        this.$message({
          type: "error",
          message: "الرجاء إدخال الرقم السري ",
        });
        return;
      }
        if (!this.form.Id && this.form.Password.length < 6) {
        this.$blockUI.Stop();
        this.$message({
          type: "error",
          message: "الرجاء إدخال الرقم السري تحتوي علي سته ارقام ",
        });
        return;
      }

        if (!this.form.Id && !this.ConfimPassword) {
        this.$blockUI.Stop();
        this.$message({
          type: "error",
          message: "الرجاء إدخال تأكيد الرقم السري ",
        });
        return;
      }

        if (!this.form.Id && this.form.Password != this.ConfimPassword) {
        this.$blockUI.Stop();
        this.$message({
          type: "error",
          message: "الرجاء التأكد من تطابق الرقم السري",
        });
        return;
      }

      if (!this.form.UserType) {
        this.$blockUI.Stop();
        this.$message({
          type: "error",
          message: "الرجاء إختيار الصفة الوظيفية",
        });
        return;
      }

      if (this.form.UserType == 2 && !this.form.HospitalId) {
        this.$blockUI.Stop();
        this.$message({
          type: "error",
          message: "الرجاء إختيار المكتب",
        });
        return;
      }

        if (this.form.UserType == 1) {
            this.form.HospitalId = 0;
        } else if (this.form.UserType == 2) {
            this.form["KidneyCentersId"] = this.form.HospitalId
        }

      //this.form.UserType = this.$parent.UserType;
      //this.form.OfficeId = (this.$parent.OfficeIdValue == "" ? 0 : this.$parent.OfficeIdValue);

      if (this.$parent.EditUsersObj) {
        this.form["Id"] = this.$parent.EditUsersObj.id;
        this.form["KidneyCentersId"] = this.form.HospitalId == 0 ? null : this.form.HospitalId;
        delete this.form.HospitalId;
        delete this.form.Password;

        return this.$http
          .EditUser(this.form)
          .then((response) => {
            this.$message({
              type: "info",
              message: response.data,
            });
            this.$parent.GetInfo(this.$parent.pageNo);
            this.$parent.state = 0;
            this.resetform();
            this.$blockUI.Stop();
          })
          .catch((err) => {
            console.log(err);

            this.$blockUI.Stop();
            this.$message({
              type: "error",
              message: err.response.data,
            });
          });
      }

      this.form["KidneyCentersId"] =
        this.form.HospitalId == 0 ? null : this.form.HospitalId;
      delete this.form.HospitalId;
      this.$http
        .AddUser(this.form)
        .then((response) => {
          this.$message({
            type: "info",
            message: response.data,
          });
          this.$parent.GetInfo(this.$parent.pageNo);
          this.$parent.state = 0;
          this.resetform();
          this.$blockUI.Stop();
        })
        .catch((err) => {
            console.log(err);
          this.$blockUI.Stop();
          this.$message({
            type: "error",
            message: err.response.data,
          });
        });
    },
  },
};
